@import 'https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Raleway:wght@200&display=swap';
@import './Components/Dashboard/styles.scss';
@import './Components/NavBar/NavBar.scss';
@import './Components/Dashboard/HistoryMetrics/styles.scss';

body, html{
  height: 100%;
  margin: 0;
  padding: 0;
}

.casaEnBlanco{
  background-image: url('./IMAGES/CASA-EN-BLANCO.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.logo-png{
  text-align: center !important;
}

.pointer{
  cursor: pointer !important;
}
.bg-gray{
  background-color: #b7b5b5d6 !important;
}

.h-card{
  height: 50px;
  @media screen and (min-width: 350px) and (max-width: 992px){
    height: 150px;
  }

  @media screen and (min-width: 993px) and (max-width: 1200px){
    height: 200px !important;
  }

  @media screen and (min-width: 1400px) and (max-width: 2000px){
    height: 180px;
  }
}

.border-white{
  border-top: 1px solid white;
}

.img-carrousel{
  width: auto;
  padding: 0.25rem;
  background-color: rgb(253, 253, 253);
  border-radius: 0.25rem;
  height: 80px;

  @media screen and (min-width: 350px) and (max-width: 400px){
    width: auto;
    height: 75px;
  }

  @media screen and (min-width: 401px) and (max-width: 576px){
    width: auto;
    height: 75px;
  }

  @media screen and (min-width: 577px) and (max-width: 768px){
    width: auto;
    height: 50px;
  }

  @media screen and (min-width: 769px) and (max-width: 992px){
    width: auto;
    height: 50px;
  }
}

.vh-100-casa{
  height: 100vh;

  @media screen and (min-width: 350px) and (max-width: 576px){
    height: 130vh;
  }

  @media screen and (min-width: 577px) and (max-width: 768px){
    height: 115vh;
  }

  @media screen and (min-width: 769px) and (max-width: 992px){
    height: 112vh;
  }

  @media screen and (min-width: 993px) and (max-width: 1300px){
    height: 140vh;
  }
}

.bg-white{
  background-color: #fff !important;
}

.text-carrousel{
  font-family: 'Raleway', sans-serif;
  font-size: 15px;
  font-weight: bold;
  color: black;

  @media screen and (min-width: 350px) and (max-width: 992px){
    font-size: 12px;
  }
}

.title-edificor{
  color: black;
  word-spacing: 3px;
  text-align: center;
  font-size: 35px;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;

  @media screen and (min-width: 350px) and (max-width: 992px){
    font-size: 20px;
  }
}

.text-description-edif{
  color: black;
  word-spacing: 3px;
  text-align: center;
  font-size: 21px;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;

  @media screen and (min-width: 350px) and (max-width: 992px){
    font-size: 15px;
  }
}

.text-description-2{
  color: black;
  font-size: 20px;
  word-spacing: 3px;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;

  @media screen and (min-width: 350px) and (max-width: 992px){
    font-size: 15px;
  }
}

.text-description-3{
  color: black;
  font-size: 16px;
  word-spacing: 3px;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;

  @media screen and (min-width: 350px) and (max-width: 992px){
    font-size: 10px;
  }
}

.text-footer{
  color: white;
  word-spacing: 3px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 3px;

  @media screen and (min-width: 350px) and (max-width: 992px){
    font-size: 15px;
  }
}

.box-redes{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.box-red{
  margin: 35px;
  width: 200px !important;
  text-align: center;
}

.text-red{
  width: 200px;
  text-decoration: none;
  font-size: 20px;
  color: black;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  cursor: pointer;
  &:hover{
    color: blue;
  }
}

.text-visita{
  text-align: center;
  font-size: 25px;
}

.text-purple{
  color: rgb(223, 24, 223);
}

.animationSpawn{
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  }
  @-webkit-keyframes zoomIn {
  0% {
  opacity: 0;
  -webkit-transform: scale3d(.3, .3, .3);
  transform: scale3d(.3, .3, .3);
  }
  50% {
  opacity: 1;
  }
  }
  @keyframes zoomIn {
  0% {
  opacity: 0;
  -webkit-transform: scale3d(.3, .3, .3);
  transform: scale3d(.3, .3, .3);
  }
  50% {
  opacity: 1;
  }
}

.w-btn-carrousel{
  width: 75px !important;

  @media screen and (min-width: 1200px){
    width: 150px !important;
  }
}

.mt-footer{
  margin-top: -6px;
}

.bg-footer{
  background-color: #282f39;
}
.d-none{
  display: none;
}

.btn{
  @media screen and (min-width: 350px) and (max-width: 992px){
    font-size: 12px !important;
  }
  
}

.spinner.morph {
  width: 100px;
  height: 100px;
  display: inline-block;
  box-sizing: border-box;
  background: #28A745;
  animation: morph-animate 1s linear infinite;
}

@keyframes morph-animate {
	0% {
		transform: rotate(0deg) scale(1);
		border-radius: 0%;
		background: #28A745;

	}

	25%, 75% {
		transform: rotate(180deg) scale(.4);
		border-radius: 50%;
		background: #28A745;
	}

	100%{
		transform: rotate(360deg) scale(1);
		border-radius: 0%;
		background: #28A745;
	}
}
