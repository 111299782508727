.bg-header{
    background-color: rgba(128, 128, 128, 0.233);
}

.text-header{
    font-size: 23px !important;
    font-weight: 400 !important;
    letter-spacing: 2px;
    color: black;

    @media screen and (min-width: 350px) and (max-width: 576px){
        font-size: 15px !important;
    }
}

.text-marca{
    font-size: 18px;
    font-weight: bold;
    color: black;

    @media screen and (min-width: 350px) and (max-width: 576px){
        font-size: 12px !important;
    }
}

.text-clicks{
    font-size: 18px;
    font-weight: 500;
    color: black;
    
    @media screen and (min-width: 350px) and (max-width: 576px){
        font-size: 12px !important;
    }
}

.bgHover{
    &:hover{
        background-color: rgba(128, 128, 128, 0.103);
        border-radius: 12px;
        cursor: pointer;
    }
}

.hoverWhite{
    &:hover{
        color: white;
    }
}

.sb-nav-fixed #layoutSidenav #layoutSidenav_nav{
    height: 100vh !important;
    @media screen and (min-width: 350px) and (max-width: 1650px){
        height: auto !important;
    }
}