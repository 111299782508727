.w-pdf{
    width: 85% !important;
    margin-bottom: 150px;
}

.pointerPdf{
    cursor: pointer;
    color: red;
    padding-bottom: 3px;
    font-size: 45px;
    &:hover{
        border-bottom: 2px solid red;
        font-size: 46px;
    }
}

@page {
    size: A4 !important;
    margin-top: 40px !important;
    margin-bottom: 45px !important;
}

@media print {
    body, html {
        width: 21cm !important;
        height: 25cm !important;
        margin: 15px;
    }
    .print-preview {
        width: 21cm !important;
        height: 25cm !important;
        margin: 15px !important;
    }
}
